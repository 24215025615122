<template>
  <v-container>
      <v-dialog v-model="reportsDialog" max-width="600px" persistent>
          <v-card>
              <v-card-title>
                  <v-btn @click="createReport">Создать</v-btn>
              </v-card-title>
              <v-card-text>
                  <v-data-table :items="reportsList" :headers="[{text: 'ID', value: 'id'}, {text: 'Название', value: 'title'}, {text: 'Действия', value: 'actions'}]">
                      <template v-slot:item.actions="{item}">
                          <v-btn fab x-small text @click="open(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
                          <v-btn fab x-small text @click="deleteProduct(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                      </template>
                  </v-data-table>
              </v-card-text>
          </v-card>
      </v-dialog>
      <v-dialog v-model="reportPropDialog" max-width="400px" scrollable persistent>
          <v-form v-model="validProps"
                  lazy-validation ref="props">
              <v-card>
                  <v-card-title>Свойства отчета</v-card-title>
                  <v-card-text>
                      <v-text-field v-if="reportProps.id" v-model="reportProps.id" label="ID" disabled></v-text-field>
                      <v-text-field v-model="reportProps.title" label="Название" :rules="[v => !!v  || 'Обязательное поле']"></v-text-field>
                      <v-text-field v-model="reportProps.width" label="Ширина страницы" suffix="см" :rules="[v => !!v  || 'Обязательное поле']"></v-text-field>
                      <v-text-field v-model="reportProps.height" label="Высота страницы" suffix="см" :rules="[v => !!v  || 'Обязательное поле']"></v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="pa-4 pr-5"><v-spacer></v-spacer>
                      <v-btn @click="saveProps" class="primary">Сохранить</v-btn>
                      <v-btn @click="reportPropDialog = false" text>Отмена</v-btn>
                  </v-card-actions>
              </v-card>
          </v-form>
      </v-dialog>
      <v-toolbar dense>
          <v-btn
              text
              @click="addText"
          >
              <v-icon>mdi-text-box-plus-outline</v-icon>
          </v-btn>
          <v-btn
              text
              @click="saveReport"
          >
              <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn v-if="activeElement == 0 || activeElement > 0"
              text
              @click="removeElement"
          >
              <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
              text
              @click="addBarcode"
          >
              <v-icon>mdi-barcode</v-icon>
          </v-btn>
          <template v-if="activeElement == 0 || activeElement > 0">


          <v-overflow-btn
              :items="dropdown_font"
              label="Select font"
              hide-details
              class="pa-0"
              v-model="report.elements[activeElement].fontFamily"
          ></v-overflow-btn>

              <v-divider vertical></v-divider>

              <v-overflow-btn
                  :items="dropdownFontSizes"
                  editable
                  label="Select size"
                  hide-details
                  class="pa-0"
                  overflow
                  @keyup.enter="checkFontValue"
                  @blur="checkFontValue"
                  v-model="report.elements[activeElement].fontSize"
              ></v-overflow-btn>

              <v-divider vertical></v-divider>

              <v-spacer></v-spacer>

              <v-btn-toggle
                  v-model="report.elements[activeElement].fontStyle"
                  color="primary"
                  dense
                  group
                  multiple
              >
                  <v-btn
                      value="bold"
                      text
                  >
                      <v-icon>mdi-format-bold</v-icon>
                  </v-btn>

                  <v-btn
                      value="italic"
                      text
                  >
                      <v-icon>mdi-format-italic</v-icon>
                  </v-btn>

                  <v-btn
                      value="underline"
                      text
                  >
                      <v-icon>mdi-format-underline</v-icon>
                  </v-btn>


              </v-btn-toggle>

              <div class="mx-4"></div>

              <v-btn-toggle
                  v-model="report.elements[activeElement].align"
                  color="primary"
                  dense
                  group
              >
                  <v-btn
                      value="left"
                      text
                  >
                      <v-icon>mdi-format-align-left</v-icon>
                  </v-btn>

                  <v-btn
                      value="center"
                      text
                  >
                      <v-icon>mdi-format-align-center</v-icon>
                  </v-btn>

                  <v-btn
                      value="right"
                      text
                  >
                      <v-icon>mdi-format-align-right</v-icon>
                  </v-btn>
              </v-btn-toggle>
          </template>

      </v-toolbar>

      <div style="background-color: #cccccc;width: 100%; height: 100%; padding: 5px" @click="removeSelection" @keydown.delete="removeElement">
          <div @click.stop="" :style="{width: report.general.width + 'cm', height: report.general.height + 'cm'}" style="background-color: white;position:relative" id="canvas" @dblclick.prevent="reportProps = report.general,reportPropDialog=true">
              <template v-for="(element,id) in report.elements">
                  <div v-if="element.type == 'text'" class="outer" @dblclick.prevent.stop="changeText(element)" @mousedown="mouseResize($event, element)" :class="{draggable: element.active}" :key="id" :style="{left: element.left+'px', top: element.top+'px', width: element.width+'px', height: element.height+'px'}" style="display: inline-block; position: absolute; padding: 1px;margin: 0;cursor: crosshair;overflow: hidden " >
                      <div class="inner" tabindex="0" :style="elementStyle(element)" @mousedown.stop="mouseDown($event, element)" style="cursor:pointer; width: 100%;height:100%; padding: 0;margin: 0">{{element.text}}</div>
                  </div>
                  <div v-else-if="element.type == 'barcode'" class="outer" @dblclick.prevent.stop="changeText(element)" @mousedown="mouseResize($event, element)" :class="{draggable: element.active}" :key="id" :style="{left: element.left+'px', top: element.top+'px', width: element.width+'px', height: element.height+'px'}" style="display: inline-block; position: absolute; padding: 1px;margin: 0;cursor: crosshair;overflow: hidden " >
                      <div class="inner" :style="elementStyle(element)" @mousedown.stop="mouseDown($event, element)" style=" cursor:pointer; width: 100%;height:100%; padding: 0;margin: 0">
                          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="enable-background:new 0 0 297 297;cursor:pointer; width: 100%;height:100%; padding: 0;margin: 1px;"
                               viewBox="40 40 220 220"  xml:space="preserve">
<g>
	<path d="M10.458,77.91C4.682,77.91,0,82.593,0,88.368v120.264c0,5.775,4.682,10.458,10.458,10.458s10.458-4.682,10.458-10.458
		V88.368C20.915,82.593,16.233,77.91,10.458,77.91z"/>
    <path d="M44.968,77.91c-5.775,0-10.458,4.682-10.458,10.458v77.387c0,5.775,4.682,10.458,10.458,10.458s10.458-4.682,10.458-10.458
		V88.368C55.426,82.593,50.744,77.91,44.968,77.91z"/>
    <path d="M79.479,77.91c-5.775,0-10.458,4.682-10.458,10.458v77.387c0,5.775,4.682,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458V88.368C89.937,82.593,85.254,77.91,79.479,77.91z"/>
    <path d="M113.989,77.91c-5.775,0-10.458,4.682-10.458,10.458v77.387c0,5.775,4.682,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458V88.368C124.447,82.593,119.765,77.91,113.989,77.91z"/>
    <path d="M148.5,77.91c-5.775,0-10.458,4.682-10.458,10.458v77.387c0,5.775,4.682,10.458,10.458,10.458s10.458-4.682,10.458-10.458
		V88.368C158.958,82.593,154.275,77.91,148.5,77.91z"/>
    <path d="M183.011,77.91c-5.775,0-10.458,4.682-10.458,10.458v77.387c0,5.775,4.683,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458V88.368C193.468,82.593,188.786,77.91,183.011,77.91z"/>
    <path d="M217.521,77.91c-5.775,0-10.458,4.682-10.458,10.458v77.387c0,5.775,4.682,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458V88.368C227.979,82.593,223.296,77.91,217.521,77.91z"/>
    <path d="M252.032,77.91c-5.775,0-10.458,4.682-10.458,10.458v77.387c0,5.775,4.682,10.458,10.458,10.458
		s10.458-4.682,10.458-10.458V88.368C262.489,82.593,257.807,77.91,252.032,77.91z"/>
    <path d="M44.968,189.808c-5.775,0-10.458,4.682-10.458,10.458v8.366c0,5.775,4.682,10.458,10.458,10.458
		s10.458-4.682,10.458-10.458v-8.366C55.426,194.491,50.744,189.808,44.968,189.808z"/>
    <path d="M79.479,189.808c-5.775,0-10.458,4.682-10.458,10.458v8.366c0,5.775,4.682,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458v-8.366C89.937,194.491,85.254,189.808,79.479,189.808z"/>
    <path d="M113.989,189.808c-5.775,0-10.458,4.682-10.458,10.458v8.366c0,5.775,4.682,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458v-8.366C124.447,194.491,119.765,189.808,113.989,189.808z"/>
    <path d="M148.5,189.808c-5.775,0-10.458,4.682-10.458,10.458v8.366c0,5.775,4.682,10.458,10.458,10.458s10.458-4.682,10.458-10.458
		v-8.366C158.958,194.491,154.275,189.808,148.5,189.808z"/>
    <path d="M183.011,189.808c-5.775,0-10.458,4.682-10.458,10.458v8.366c0,5.775,4.683,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458v-8.366C193.468,194.491,188.786,189.808,183.011,189.808z"/>
    <path d="M217.521,189.808c-5.775,0-10.458,4.682-10.458,10.458v8.366c0,5.775,4.682,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458v-8.366C227.979,194.491,223.296,189.808,217.521,189.808z"/>
    <path d="M252.032,189.808c-5.775,0-10.458,4.682-10.458,10.458v8.366c0,5.775,4.682,10.458,10.458,10.458
		s10.458-4.682,10.458-10.458v-8.366C262.489,194.491,257.807,189.808,252.032,189.808z"/>
    <path d="M286.542,77.91c-5.775,0-10.458,4.682-10.458,10.458v120.264c0,5.775,4.682,10.458,10.458,10.458
		c5.775,0,10.458-4.682,10.458-10.458V88.368C297,82.593,292.318,77.91,286.542,77.91z"/>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
                              <g>
</g>
</svg>
                          {{element.text}}</div>
                  </div>
              </template>
          </div>
          <a href="http://vyatkina.test/api/pdf">sdfsdfsd</a>
          <v-btn @click="print">Print</v-btn>
      </div>
      <v-overlay :value="overlay" style="z-index:1100">

          <div>
              <v-alert v-if="!errorMessage" type="success" >
                  Успешно
              </v-alert>
              <v-alert v-else type="error" >
                  {{errorMessage}}
              </v-alert>
          </div>
          <div @click="overlay = false" style="position: fixed; top:0; bottom: 0; left: 0; right:0; background-color: rgba(255,255,255,0); opacity: 0.1"></div>

      </v-overlay>
      <v-overlay :value="progress" style="z-index:1000">
          <div class="text-center">
              <v-progress-circular
                  indeterminate
                  color="primary"
                  style="z-index:1001"
              ></v-progress-circular>
          </div>
      </v-overlay>


  </v-container>
</template>

<style>
.draggable {
    border: 0.5px dashed ;
}

    #canvas {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
</style>

<script>
   import print from 'print-js'
   import axios from "axios";

  export default {

      data: () => ({
          activeElement: null,
          overlay: false,
          errorMessage: '',
          progress: false,
          validProps: true,
          reportPropDialog: false,
          reportsDialog: true,
          reportsList: [],
          reportProps: {},
          report: {
              general: {},
              elements: []
          },
          templates: {
              text: {
                  type: 'text',
                  text: 'text',
                  left: 0,
                  top: 0,
                  width: 50,
                  height: 20,
                  align: 'left',
                  fontSize: 12,
                  fontAutoSize: true,
                  fontFamily: 'Arial',
                  fontStyle: [],
                  border: 0,
                  borderType: ''
              },
              barcode: {
                  type: 'barcode',
                  text: 'Штриход',
                  left: 0,
                  top: 0,
                  width: 100,
                  height: 50,
              }
          },

          dropdown_font: [
              { text: 'Arial' },
              { text: 'Arial Narrow' },
              { text: 'Calibri' },
              { text: 'Verdana' },
          ],
          dropdownFontSizes: [
              { text: 14 },
              { text: 13 },
              { text: 12 },
              { text: 11 },
              { text: 10 },
              { text: 9 },
              { text: 8 },
              { text: 7 },
              { text: 6 },
              { text: 5 },
          ],
      }),
      methods: {
          checkFontValue(e) {
              console.log(e);
              let val = e.target.value;
              let found = false;
              if (!isNaN(parseFloat(val)) && isFinite(val) && Number(val) < 100 && Number(val) >= 0) {
                for (let i in this.dropdownFontSizes) {
                    if (this.dropdownFontSizes[i].text == val) {
                        found = true;
                        this.report.elements[this.activeElement].fontSize = Number(val);
                    }
                }
                if (!found) {
                    this.dropdownFontSizes.splice(0, 0, {text: Number(val)});
                    this.report.elements[this.activeElement].fontSize = Number(val);
                }
              }
              if (e.key) {
                  this.$nextTick(function() {
                      e.target.dispatchEvent(new KeyboardEvent('keydown', {key: 'Escape', keyCode: 27}))
                      e.target.blur();
                  });

              }
              //trigger({type: 'keyup', which: 27});
          },
          keyPress(event) {
              if (this.activeElement >= 0)
              if (event.target.tagName.toUpperCase() == 'BODY') {
                  if (event.key == "Delete") {
                      this.removeElement();
                  }
              }
          },
          keyDown(event) {
              let canvas = document.getElementById('canvas');
              if (this.activeElement >= 0)
              if (event.target.tagName.toUpperCase() == 'BODY') {
                  if (event.key == "ArrowRight") {
                      if (this.report.elements[this.activeElement].left + 1 < canvas.clientWidth-this.report.elements[this.activeElement].width)
                      this.report.elements[this.activeElement].left = this.report.elements[this.activeElement].left  + 1
                      else
                          this.report.elements[this.activeElement].left = canvas.clientWidth-this.report.elements[this.activeElement].width;
                  }
                  else if (event.key == "ArrowLeft") {
                      if (this.report.elements[this.activeElement].left  - 1 > 0)
                          this.report.elements[this.activeElement].left = this.report.elements[this.activeElement].left  - 1
                      else
                          this.report.elements[this.activeElement].left = 0;
                  }
                  else if (event.key == "ArrowDown") {
                      if (this.report.elements[this.activeElement].top + 1 < canvas.clientHeight-this.report.elements[this.activeElement].height)
                          this.report.elements[this.activeElement].top = this.report.elements[this.activeElement].top  + 1
                      else
                          this.report.elements[this.activeElement].top = canvas.clientHeight-this.report.elements[this.activeElement].height;
                  }
                  else if (event.key == "ArrowUp") {
                      if (this.report.elements[this.activeElement].top - 1 > 0)
                          this.report.elements[this.activeElement].top = this.report.elements[this.activeElement].top  - 1
                      else
                          this.report.elements[this.activeElement].top = 0;
                  }
              }
          },
          removeSelection() {
              for (let i in this.report.elements) {
                  this.activeElement = null;
                  this.report.elements[i].active = false;
              }
          },
          addText() {
              this.report.elements.splice(-1, 0, JSON.parse(JSON.stringify(this.templates.text)));
              for (let i in this.report.elements) {
                  this.report.elements[i].active = false;
                  if (this.report.elements.length-1 == i) {
                      this.report.elements[i].active = true;
                      this.activeElement =  i;
                  }
              }
          },
          addBarcode() {
              this.report.elements.splice(-1, 0, JSON.parse(JSON.stringify(this.templates.barcode)));
              for (let i in this.report.elements) {
                  this.report.elements[i].active = false;
                  if (this.report.elements.length-1 == i) {
                      this.report.elements[i].active = true;
                      this.activeElement =  i;
                  }
              }
          },
          removeElement() {
              if (this.activeElement == 0 || this.activeElement > 0) {
                  this.report.elements.splice(this.activeElement, 1);
                  this.activeElement =  null;
              }
          },
          open(item) {
              let $this = this;
              this.progress = true;
              axios({
                  url: '/api/report/edit',
                  params: {id: item.id},
                  method: 'GET',
              }).then(function (response) {
                  if (!response.data.error) {
                      $this.report = response.data.report;
                      $this.reportsDialog = false;
                      $this.progress = false;
                  }
                  else {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });
          },
          saveReport() {

              let $this = this;
              this.progress = true;
              axios({
                  url: '/api/report/save',
                  params: {id: this.report.general.id},
                  method: 'POST',
                  data: {
                      report: this.report
                  }
              }).then(function (response) {
                  if (response.data.reports) {
                      $this.reports = response.data.reports;
                      $this.errorMessage = '';
                      $this.overlay = true;
                      $this.progress = false;
                      $this.activeElement = null;
                      $this.report = {
                          general: {},
                          elements: []
                      };
                      $this.reportsDialog = true;
                  }
                  else if (response.data.error) {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });
          },
          elementStyle(element) {
              let ret = {};
              if (element.fontStyle && element.fontStyle.indexOf("italic") != -1) {
                  ret.fontStyle = 'italic';
              }
              if (element.fontStyle && element.fontStyle.indexOf("bold") != -1) {
                  ret.fontWeight = 'bold';
              }
              if (element.fontStyle && element.fontStyle.indexOf("underline") != -1) {
                  ret.textDecoration = 'underline';
              }
              if (element.align) {
                  ret.textAlign = element.align;
              }
              if (element.fontFamily) {
                  ret.fontFamily = element.fontFamily;
              }
              if (element.fontSize) {
                  ret.fontSize = element.fontSize+'px';
              }
              return ret;
          },
          saveProps () {
              if (this.$refs.props.validate()) {
                  if (!isNaN(parseFloat(this.reportProps.width)) && isFinite(this.reportProps.height)) {
                      this.report.general = this.reportProps;
                      this.reportProps = {};
                      this.reportPropDialog = false;
                      this.reportsDialog = false;
                  }
                  else {
                      this.errorMessage = 'Ошибка! Неверно указана ширина/высота';
                      this.overlay = true;
                  }
              }
          },
          createReport () {
              this.reportPropDialog = true;
          },
          changeText (element) {
              let txt = prompt('Текст', element.text);
              if (txt) {
                  element.text = txt;
              }
          },
          print () {
              let $this = this;
              this.progress = true;
              axios({
                  url: '/api/report/pdf',
                  method: 'POST',
                  responseType: 'blob',
                  data: {
                      items: this.report,
                  }
              }).then(function (response) {
                  if (!response.data.error) {
                      $this.progress = false;
                      var blob = new Blob([response.data],{type:'application/pdf'});
                      var url = URL.createObjectURL(blob);
                      print(url);
                  }
                  else {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });
          },
          mouseResize(event, element) {

                  let canvas = document.getElementById('canvas');

              let outer = event.target;

              let direction = '';
              let initPos = 0;
              let initDimension = 0;

              if (outer) {
                  outer.onmousedown = function(event) {
                      event.preventDefault(); // предотвратить запуск выделения (действие браузера)
                          direction = '';
                          if (event.clientX - outer.getBoundingClientRect().left < 2) {
                              direction = 'left'
                              initPos = event.clientX;
                              initDimension = outer.offsetWidth;
                          }
                          else if (outer.getBoundingClientRect().right - event.clientX < 2) {
                              direction = 'right';
                              initPos = event.clientX;
                              initDimension = outer.offsetWidth;
                          }
                          else if (event.clientY - outer.getBoundingClientRect().top < 2) {
                              direction = 'top';
                              initPos = event.clientY;
                              initDimension = outer.offsetHeight;
                          }
                          else if (outer.getBoundingClientRect().bottom - event.clientY < 2) {
                              direction = 'bottom';
                              initPos = event.clientY;
                              initDimension = outer.offsetHeight;
                          }
                          if (direction !== '') {
                              document.addEventListener('mousemove', startResize);
                              document.addEventListener('mouseup', stopResize);
                          }



                      function startResize(event) {
                          let pos = 0;
                          if (direction == 'right') {
                              pos = event.clientX;
                              if (canvas.getBoundingClientRect().right < event.clientX) {
                                  pos = canvas.getBoundingClientRect().right;
                              }
                              element.width = (initDimension + (pos - initPos));
                              if (element.width > canvas.clientWidth) {
                                  element.width = canvas.clientWidth;
                              }
                          }
                          else if (direction == 'left') {
                              pos = event.clientX;
                              if (canvas.getBoundingClientRect().left > event.clientX) {
                                  pos = canvas.getBoundingClientRect().left;
                              }
                              if (initDimension < pos - initPos+5) {
                                  pos = initPos + initDimension-5;
                              }
                              element.width = (initDimension + (initPos - pos));
                              if (element.width > canvas.clientWidth) {
                                  element.width = canvas.clientWidth;
                              }
                              element.left = (pos - canvas.getBoundingClientRect().left);

                          }
                          else if (direction == 'top') {
                              pos = event.clientY;
                              if (canvas.getBoundingClientRect().top > event.clientY) {
                                  pos = canvas.getBoundingClientRect().top;
                              }
                              if (initDimension < pos - initPos+5) {
                                  pos = initPos + initDimension-5;
                              }
                              element.height = (initDimension + (initPos - pos));
                              element.top = (pos - canvas.getBoundingClientRect().top);
                          }
                          else if (direction == 'bottom') {
                              pos = event.clientY;
                              if (canvas.getBoundingClientRect().bottom < event.clientY) {
                                  pos = canvas.getBoundingClientRect().bottom;
                              }
                              element.height = (initDimension + (pos - initPos));
                          }
                      }

                      function stopResize() {
                          document.removeEventListener('mouseup', stopResize);
                          document.removeEventListener('mousemove', startResize);
                      }

                  }

              }
              event.target.ondragstart = function() {
                  return false;
              };
          },
          mouseDown(event, element) {
              for (let i in this.report.elements) {
                  this.report.elements[i].active = false;

              }
              element.active = true;
              for (let i in this.report.elements) {
                  if (this.report.elements[i].active) {
                      this.activeElement = i;
                  }
              }
              let canvas = document.getElementById('canvas');
              let obj = event.target;
              // let outer = obj.parentElement;
              event.preventDefault(); // предотвратить запуск выделения (действие браузера)
              let shiftX = event.clientX - obj.getBoundingClientRect().left;
              let shiftY = event.clientY - obj.getBoundingClientRect().top;
              let initX = event.clientX;
              let initY = event.clientY;
              // shiftY здесь не нужен, слайдер двигается только по горизонтали

              document.addEventListener('mousemove', onMouseMove);
              document.addEventListener('mouseup', onMouseUp);

              function onMouseMove(event) {
                  let minX = Math.min(initX, event.clientX);
                  let maxX = Math.max(initX, event.clientX);
                  let minY = Math.min(initY, event.clientY);
                  let maxY = Math.max(initY, event.clientY);
                  if (maxX - minX < 5 && maxY - minY < 5) {
                      return;
                  }
                  initX = 0;
                  initY = 0;
                  let newLeft = event.clientX - shiftX - canvas.getBoundingClientRect().left;

                  // курсор вышел из слайдера => оставить бегунок в его границах.
                  if (newLeft < 0) {
                      newLeft = 0;
                  }

                  if (newLeft > canvas.clientWidth-element.width) {
                      newLeft = canvas.clientWidth-element.width;
                  }




                  element.left = newLeft;

                  let newTop = event.clientY - shiftY - canvas.getBoundingClientRect().top;

                  // курсор вышел из слайдера => оставить бегунок в его границах.
                  if (newTop < 0) {
                      newTop = 0;
                  }


                  if (newTop > canvas.clientHeight-element.height) {
                      newTop = canvas.clientHeight-element.height;
                  }

                  element.top = newTop;
              }

              function onMouseUp() {
                  document.removeEventListener('mouseup', onMouseUp);
                  document.removeEventListener('mousemove', onMouseMove);
              }
              event.target.ondragstart = function() {
                  return false;
              };
          }
      },

      watch: {

      },
      destroyed() {
          window.removeEventListener('keyup', this.keyPress);
          window.removeEventListener('keydown', this.keyDown);
      },
      mounted() {
          window.addEventListener('keyup', this.keyPress);
          window.addEventListener('keydown', this.keyDown);
          let canvas = document.getElementById('canvas');
          canvas.ondragstart = function() {
              return false;
          };
          this.report.elements.splice(-1, 0, JSON.parse(JSON.stringify(this.templates.text)));
          this.report.elements.splice(-1, 0, JSON.parse(JSON.stringify(this.templates.text)));
          let $this = this;
          this.progress = true;
          axios({
              url: '/api/report/get',
              method: 'GET',
          }).then(function (response) {
              if (!response.data.error) {
                  $this.reportsList = response.data.reports;
                  $this.progress = false;
              }
              else {
                  $this.errorMessage = response.data.error;
                  $this.overlay = true;
                  $this.progress = false;
              }
          })
              .catch(function () {
                  $this.errorMessage = 'Ошибка';
                  $this.overlay = true;
                  $this.progress = false;
              });

      }
  }
</script>
